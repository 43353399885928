import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imgNeggin from '../img/neggin.jpg'

const Page = () => (
  <Layout pageName="about-us">
    <SEO title="About Us" />
    <div id="primary" className="content-area">
      <main id="main" className="site-main" role="main">
        <article id="post-43" className="post-43 page type-page status-publish hentry">
          <div className="image-container"><img alt="Dr Neggin Heidari-Robinson" title="Dr Neggin Heidari-Robinson" src={imgNeggin} /></div>
          <div className="text-flow">

            <p>If you are considering botulinum toxin injections or dermal fillers, you will be looking for someone who
              is skilled with her hands, expert in working in a small environment around the face, and used to providing
              a relaxing, gentle environment for treatment. Dr Neggin has exactly these qualities.</p>

            <p>Dr Neggin grew up in Surrey and attended Croydon High School. She qualified from Kings College London in
              2001 and subsequently worked as a senior house officer in Paediatric and Maxillofacial Surgery in King's
              College and Kent and Canterbury Hospitals. Neggin has completed the Royal College of Surgeons' course in
              implant dentistry and, for six years, taught students at Kings and Guys in oral surgery. She has also
              published work on the interpretation of X-rays in the British Journal of Oral and Maxillofacial Surgery.
              Neggin has experience in all aspects of general dental surgery, with a special interest in implants,
              endodontics (root canals) and aesthetic treatments. She is trained in providing botulinum toxin injections
              and dermal fillers.</p>

          </div>
        </article>
      </main>
    </div>
  </Layout>
)

export default Page